import { useQuery } from '@apollo/client'
import { Button, CircularProgress, Grid, Typography, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { GET_APPLICANT_MEDIA } from '../queries/queries'
import Medium from './Medium'
import TestimonialsListDialog from './TestimonialsListDialog'
import useCurrentSession from './useCurrentSession'
import { MediumSpacer, SuperSpacer } from './Spacers'

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  separator: {
    textAlign: 'center',
    width: '80%',
  },
  centered: {
    textAlign: 'center',
  },
  title: {
    paddingBottom: theme.spacing(1),
    fontStyle: 'italic',
  },
})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ApplicationReviewContent = ({ classes, applicant }) => {
  const currentSession = useCurrentSession()
  const [testimonialDialog, setTestimonialsDialog] = useState(false)

  const {
    data: applicantMediaQueryData,
    loading: applicantMediaQueryLoading,
    error: applicantMediaQueryError,
  } = useQuery(GET_APPLICANT_MEDIA, {
    variables: { applicantId: applicant.id },
  })

  return (
    <React.Fragment>
      <Typography variant='h5' color='secondary'>
        Basic Information
      </Typography>
      <Divider />
      <MediumSpacer />
      <Typography variant='h6' className={classes.title}>
        Application from:{' '}
        <strong>
          {applicant.name} (@{applicant.telegramUsername})
        </strong>
      </Typography>
      <MediumSpacer />
      <Typography variant='h6' className={classes.title}>
        Are you over 18?
      </Typography>
      <Typography variant='subtitle1'>{applicant.age ? 'Yes' : 'No'}</Typography>
      <DoubleSpacer />
      <Typography variant='h6' className={classes.title}>
        Do you/Have you gone by any other names?
      </Typography>
      <Typography variant='subtitle1'>{applicant.otherNames}</Typography>
      <DoubleSpacer />
      <Typography variant='h6' className={classes.title}>
        Who referred you to the group?
      </Typography>
      <Typography variant='subtitle1'>{applicant.referral}</Typography>
      <DoubleSpacer />
      <Typography variant='h6' className={classes.title}>
        How do you know your referral(s)?
      </Typography>
      <Typography variant='subtitle1'>{applicant.referralHowKnow}</Typography>
      <DoubleSpacer />
      <Typography variant='h6' className={classes.title}>
        Social accounts
      </Typography>
      <Grid container spacing={1}>
        {applicant.socialAccount.tw && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>Twitter</Typography>
            <a
              target='_blank'
              href={`https://twitter.com/${applicant.socialAccount.tw}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.tw}</Typography>
            </a>
          </Grid>
        )}
        {applicant.socialAccount.ad && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>Twitter AD</Typography>
            <a
              target='_blank'
              href={`https://twitter.com/${applicant.socialAccount.ad}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.ad}</Typography>
            </a>
          </Grid>
        )}
                {applicant.socialAccount.xt && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>BlueSky</Typography>
            <a
              target='_blank'
              href={`https://bsky.app/profile/${applicant.socialAccount.xt}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.xt}</Typography>
            </a>
          </Grid>
        )}
         {applicant.socialAccount.sf && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>BlueSky AD</Typography>
            <a
              target='_blank'
              href={`https://bsky.app/profile/${applicant.socialAccount.sf}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.sf}</Typography>
            </a>
          </Grid>
        )}
        {applicant.socialAccount.ph && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>PornHub</Typography>
            <a
              target='_blank'
              href={`https://pornhub.com/users/${applicant.socialAccount.ph}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.ph}</Typography>
            </a>
          </Grid>
        )}
        {applicant.socialAccount.fa && (
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='caption'>FurAffinity</Typography>
            <a
              target='_blank'
              href={`https://www.furaffinity.net/user/${applicant.socialAccount.fa}`}
              className={classes.link}
            >
              <Typography variant='subtitle1'>{applicant.socialAccount.fa}</Typography>
            </a>
          </Grid>
        )}
      </Grid>
      <SuperSpacer />
      <Typography variant='h6' className={classes.title}>
        Tell us a bit about yourself and why you'd like to join Shag Carpets!
      </Typography>
      <Typography variant='subtitle1' style={{ whiteSpace: "pre-wrap" }}>{applicant.bio}</Typography>
      <SuperSpacer />
      <Typography variant='h6' className={classes.title}>
        Do you currently own a fursuit?
      </Typography>
      <Typography variant='subtitle1'>{applicant.fursuit ? 'Yes' : 'No'}</Typography>
      <SuperSpacer />
      <Typography variant='h6' className={classes.title}>
        If you have a suit, is it modded?
      </Typography>
      <Typography variant='subtitle1'>{applicant.modded}</Typography>
      <SuperSpacer />
      <Typography variant='h6' className={classes.title}>
        Anything Else?
      </Typography>
      <Typography variant='subtitle1' style={{ whiteSpace: "pre-wrap" }}>{applicant.additionalInfo}</Typography>
      <SuperSpacer />
      {!currentSession.user.isApplicant && <TestimonialsListDialog applicant={applicant} />}
      <Typography variant='h5' color='secondary'>
        Media
      </Typography>
      <Divider />
      <MediumSpacer />
      {applicantMediaQueryLoading && <CircularProgress />}
      {(applicantMediaQueryError || !applicantMediaQueryData) && (
        <Typography variant='h6' className={classes.title}>
          ERROR FETCHING MEDIA
        </Typography>
      )}
      {applicantMediaQueryData && applicantMediaQueryData.applicantMedia && (
        <Grid container spacing={1}>
          {applicantMediaQueryData.applicantMedia &&
            applicantMediaQueryData.applicantMedia.map((medium) => (
              <Grid xs={12} md={6} item key={medium.id}>
                <Medium medium={medium} />
              </Grid>
            ))}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(withWidth()(ApplicationReviewContent)))
