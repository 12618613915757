import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import ResponsiveDialog from './ResponsiveDialog'

const StormyDialog = ({ open, onClose }) => {
  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Need Help?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={onClose} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <Typography variant='h6'>
            If something doesn't seem to work right, or if you need help with the website or a bug,
            please don't hesitate to contact one of the chat admins.
          </Typography>
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default StormyDialog
