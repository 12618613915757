import { useMutation } from '@apollo/client'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { UPDATE_APPLICANT } from '../queries/mutations'

const styles = (theme) => ({
  separator: {
    textAlign: 'center',
    width: '80%',
  },
  dangerButton: {
    color: theme.palette.danger.main,
  },
})

const Spacer = () => <div style={{ padding: 16 }} />
const DoubleSpacer = () => <div style={{ padding: 32 }} />

const ApplicationPartOne = ({ classes, applicant, goToPictures, setAlertDialog }) => {
  const [name, setName] = useState(applicant.name || '')
  const [accounts, setAccounts] = useState(applicant.socialAccount || {})
  const [telegramUsername, setTelegramUsername] = useState(applicant.telegramUsername || '')
  const [otherNames, setOtherNames] = useState(applicant.otherNames || '')
  const [referral, setReferral] = useState(applicant.referral || '')
  const [referralHowKnow, setReferralHowKnow] = useState(applicant.referralHowKnow || '')
  const [bio, setBio] = useState(applicant.bio || '')
  const [additionalInfo, setAdditionalInfo] = useState(applicant.additionalInfo || '')
  const [age, setAge] = useState(applicant.age !== '' ? (applicant.age ? 'yes' : 'no') : null)
  const [fursuitRadio, setFursuitRadio] = useState(
    applicant.fursuit !== '' ? (applicant.fursuit ? 'yes' : 'no') : null
  )
  const [modded, setModded] = useState(applicant.modded || '')
  const [updateApplicant, { loading: updateApplicantloading }] = useMutation(UPDATE_APPLICANT)

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={0} alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' noWrap color={'inherit'}>
              Fill Application
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={() => setAlertDialog(true)} aria-label='Close'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          Any information collected here will be kept private, and will not be distributed to anyone
          outside the chat. It will be used for the sole purpose of creating a poll so that the chat
          can vote in favor of or against adding you.
          <br />
          <br />
          Remember, the more information you provide - the better you sell yourself, the more likely
          it is that you'll be voted in. All fields are heavily suggested, even if marked optional.
          <br />
          <br />
          If you have any questions or require any assistance, please contact @Keeyakanze,
          @XepherSicarius, @ChairoRaccoon, @KaitosLeopard, @Vignette, @Sheppfox, @SakoShep or
          @Fenny_AD on telegram.
        </Typography>
        <Spacer />
        <hr className={classes.separator} />
        <Spacer />
        <Typography variant='h6'>Basic Information</Typography>
        <TextField
          variant='outlined'
          label='Name'
          name='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin='dense'
          fullWidth
        />
        <TextField
          variant='outlined'
          label='Telegram Username'
          name='telegramUsername'
          value={telegramUsername}
          onChange={(e) => setTelegramUsername(e.target.value)}
          margin='dense'
          fullWidth
        />
        <DoubleSpacer />
        <Typography variant='h6'>Are you over 18?</Typography>
        <RadioGroup
          aria-label='age'
          name='age'
          value={age}
          onChange={(e) => setAge(e.target.value)}
        >
          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
          <FormControlLabel value='no' control={<Radio />} label='No' />
        </RadioGroup>
        <DoubleSpacer />
        <Typography variant='h6'>Do you/Have you gone by any other names?</Typography>
        <Typography variant='subtitle2'>
          We ask, because people want to know who they're voting on. Does your fursuit have a name
          you don't go by otherwise? Did you change names?
        </Typography>
        <TextField
          variant='outlined'
          label='Other Names'
          name='otherNames'
          value={otherNames}
          onChange={(e) => setOtherNames(e.target.value)}
          margin='dense'
          fullWidth
        />
        <DoubleSpacer />
        <Typography variant='h6'>Who referred you to the group?</Typography>
        <TextField
          variant='outlined'
          label='Referral'
          name='referral'
          value={referral}
          onChange={(e) => setReferral(e.target.value)}
          margin='dense'
          fullWidth
        />
        <DoubleSpacer />
        <Typography variant='h6'>How do you know your referral(s)?</Typography>
        <Typography variant='subtitle2'>
          Your referral had to tell us all about how they knew you. It's your turn~
        </Typography>
        <TextField
          variant='outlined'
          label='Your Answer'
          name='referralHowKnow'
          value={referralHowKnow}
          onChange={(e) => setReferralHowKnow(e.target.value)}
          margin='dense'
          fullWidth
        />
        <DoubleSpacer />
        <Typography variant='h6'>Social accounts</Typography>
        <Typography variant='subtitle2'>
          Only provide the handle for each account (e.g. if your twitter is @HornyFox123 just
          provide that, not the full URL)
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Twitter'
              name='accountsTW'
              value={accounts.tw}
              onChange={(e) => setAccounts({ ...accounts, tw: e.target.value })}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Twitter AD'
              name='accountsAD'
              value={accounts.ad}
              onChange={(e) => setAccounts({ ...accounts, ad: e.target.value })}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='BlueSky'
              name='accountsXT'
              value={accounts.xt}
              onChange={(e) => setAccounts({ ...accounts, xt: e.target.value })}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='BlueSky AD'
              name='accountsSF'
              value={accounts.sf}
              onChange={(e) => setAccounts({ ...accounts, sf: e.target.value })}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='PornHub'
              name='accountsPH'
              value={accounts.ph}
              onChange={(e) => setAccounts({ ...accounts, ph: e.target.value })}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='FurAffinity'
              name='accountsFA'
              value={accounts.fa}
              onChange={(e) => setAccounts({ ...accounts, fa: e.target.value })}
              margin='dense'
            />
          </Grid>
        </Grid>
        <DoubleSpacer />
        <Typography variant='h6'>
          Tell us a bit about yourself and why you'd like to join Shag Carpets!
        </Typography>
        <Typography variant='subtitle2'>
          Introduce yourself! What's your name? Where ya from? How old are ya? When and how did you
          find furry? If you're a suiter, when did you get your suit? How did you get into
          murrsuiting? This is your chance to talk about yourself, give others a chance to get to
          know you. Why are you interested in Shag Carpets? There are a number of open invite
          murrsuit groups... What has you interested in the group you have to get voted into? The
          more you have to say, the better it looks for you.
        </Typography>
        <TextField
          variant='outlined'
          label='Your Answer'
          name='bio'
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          rows={6}
          rowsMax={100}
        />
        <DoubleSpacer />
        <Typography variant='h6'>Do you currently own a fursuit?</Typography>
        <Typography variant='subtitle2'>
          Not having a suit does not exclude you from the group! We just like to know either way :)
        </Typography>
        <RadioGroup
          aria-label='fursuit'
          name='fursuitRadio'
          value={fursuitRadio}
          onChange={(e) => setFursuitRadio(e.target.value)}
        >
          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
          <FormControlLabel value='no' control={<Radio />} label='No' />
        </RadioGroup>
        <DoubleSpacer />
        <Typography variant='h6'>If you have a suit, is it modded?</Typography>
        <Typography variant='subtitle2'>
          Again, just something the group likes to know. Extra zips are not a requirement for
          membership.
        </Typography>
        <RadioGroup
          aria-label='modded'
          name='modded'
          value={modded}
          onChange={(e) => setModded(e.target.value)}
        >
          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
          <FormControlLabel value='soon' control={<Radio />} label='Soon to be' />
          <FormControlLabel value='no' control={<Radio />} label='No' />
          <FormControlLabel value='partial' control={<Radio />} label='N/A (Partial Suit)' />
          <FormControlLabel value='no_suit' control={<Radio />} label='N/A (No Suit)' />
        </RadioGroup>
        <DoubleSpacer />
        <Typography variant='h6'>Anything Else?</Typography>
        <Typography variant='subtitle2'>
          Is there anything else you'd like to share with the group for their consideration when
          voting on you?
        </Typography>
        <TextField
          variant='outlined'
          label='Your Answer'
          name='additionalInfo'
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          margin='dense'
          fullWidth
          multiline
          rows={6}
          rowsMax={10}
        />
        <Spacer />
        <hr className={classes.separator} />
        <Spacer />
        <Typography variant='h6'>Next step: Pics!</Typography>
      </DialogContent>
      <DialogActions>
        {updateApplicantloading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() =>
              updateApplicant({
                variables: {
                  input: {
                    id: applicant.id,
                    name,
                    telegramUsername,
                    age: age === 'yes',
                    otherNames,
                    referral,
                    socialAccount: JSON.stringify(accounts),
                    referralHowKnow,
                    bio,
                    fursuit: fursuitRadio === 'yes',
                    modded,
                    additionalInfo,
                  },
                },
              }).then(({ data }) => {
                goToPictures()
              })
            }
          >
            SAVE AND GO TO MEDIA UPLOAD
          </Button>
        )}
        <Button className={classes.dangerButton} onClick={() => setAlertDialog(true)}>
          CANCEL
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default withStyles(styles)(ApplicationPartOne)
